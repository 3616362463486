.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  background: url("https://www.pixelstalk.net/wp-content/uploads/images6/Aesthetic-Rose-Wallpaper-Red.jpg") no-repeat center top; 
  background-size: cover; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 0;
  transform: scale(0.9); /* Empieza un poco más pequeño */
  transition: opacity 0.7s ease-in-out, transform 0.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
  transform: scale(1); /* Vuelve a su tamaño normal */
}
/* Estilos generales para la imagen main-text */
.main-text {
  max-width: 100%; /* Ajusta la imagen al ancho del contenedor */
}

/* Media query para dispositivos móviles */
@media (max-width: 767px) {
  /* Dispositivos móviles */
  .main-text {
    max-width: 80%; /* Reducir el ancho de la imagen en dispositivos móviles */
  }
}
.main-image{
  width: 100%;
}