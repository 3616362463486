@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");

.event-info {
  text-align: center;
  margin-bottom: 2rem;
}

.event-info .event-logo {
  width: 100px;
  margin-top: 1rem; /* Ajuste para centrar verticalmente el logo */
}
.event-info .liverpool {
  width: 200px;
}

.event-info .event-details h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.date-and-time {
  display: flex;
  align-items: center;
  justify-content: center; /* Centra los elementos horizontalmente */
  margin-bottom: 1rem;
  gap: 1em;
  font-size: 2em;
}

.date-and-time .divider {
  width: 2px; /* Establece el ancho del divider como vertical */
  height: 30px; /* Establece la altura del divider */
  background-color: #ccc;
  margin: 0 0.5rem; /* Ajuste de margen */
}

.location {
  text-align: center;
  margin-bottom: 1rem;
}

.button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: #680517;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 1em;
}

.button:hover {
  background-color: #8d061f;
}
.roadmap-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
}

.roadmap {
  max-width: 100%;
  margin: 2em;
}

.timeline {
  list-style: none;
  display: flex;
  flex-wrap: wrap; /* Cambiado a wrap para que los elementos se ajusten en filas */
  align-items: center;
  justify-content: center; /* Centra los elementos horizontalmente */
  margin-top: 2em;
}

.timeline-item {
  margin-right: 40px; /* Ajusta la separación horizontal entre elementos */
  flex: 1 0 300px; /* Establece el ancho mínimo de los elementos y flex-grow para que se expandan igualmente */
  max-width: 300px; /* Establece el ancho máximo de los elementos */
  margin-bottom: 20px; /* Agrega un espacio entre las filas */
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-weight: bold;
  width: 100%;

}

.hour,
.activity {
  text-align: center;
  margin-top: 5px; /* Ajusta la separación entre la hora y la actividad */
  font-family: "EB Garamond";
  font-weight: "regular";
  margin-top: 1rem;
  font-size: 1.5rem;
}

/* Media queries para ajustar el número de actividades por fila en diferentes tamaños de pantalla */
@media only screen and (min-width: 768px) {
  /* Tablets y dispositivos más grandes */
  .timeline-item {
    flex: 1 0 calc(33.333% - 40px); /* Calcula el ancho de cada elemento para que haya 3 por fila */
    max-width: calc(
      33.333% - 40px
    ); /* Establece el ancho máximo de cada elemento */
    margin-bottom: 20px; /* Agrega un espacio entre las filas */
  }
}
@media only screen and (min-width: 768px) {
  /* Tablets y dispositivos más grandes */
  .timeline-item {
    flex: 1 0 calc(33.333% - 40px); /* Calcula el ancho de cada elemento para que haya 3 por fila */
    max-width: calc(33.333% - 40px); /* Establece el ancho máximo de cada elemento */
    margin-bottom: 20px; /* Agrega un espacio entre las filas */
  }
}

@media only screen and (max-width: 767px) {
  /* Dispositivos móviles */
  .timeline {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los elementos verticalmente */
  }

  .timeline-item {
    flex: 1 0 calc(100% - 40px); /* Calcula el ancho de cada elemento para que ocupe todo el ancho menos el margen */
    max-width: calc(100% - 40px); /* Establece el ancho máximo de cada elemento */
    margin-bottom: 20px; /* Agrega un espacio entre los elementos */
  }
}

.parent-info {
  display: flex;
  justify-content: space-between;
  gap: 15em;
  font-family: "EB Garamond";
  font-weight: "lighter";
}

.parent {
  display: flex;
  align-items: center;
  font-family: "EB Garamond";
  font-weight: "lighter";
}

.parent-names {
  font-family: "EB Garamond";
  font-weight: "lighter";
}
.parent-names .names {
  font-family: "EB Garamond";
  font-weight: "lighter";
  font-size: 24px;
}

.icon {
  font-size: 24px;
}
.hotel-icon {
  width: 100px;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .parent-info {
    flex-direction: column;
    gap: 1em;
    justify-content: center;


  }
  .parent{
    display: block;
  }

}