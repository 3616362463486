.event-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.form-info {
  width: 30%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
}
@media (max-width: 1468px) {
  .form-info {
    width: 70%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
  }
}
/* Media query para tablet */
@media (max-width: 768px) {
  .form-info {
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
  }
}

/* Media query para móvil */
@media (max-width: 480px) {
  .form-info {
    width: 85%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
  }
}

.event-details {
  text-align: center;
}

.confirmation-message {
  margin-top: 10px;
}

.divider {
  width: 75%;
  background-color: #000;
  margin: 15px auto;
}

.invited-info {
  margin-top: 10px;
}

.confirmation-form {
  margin-top: 20px;
}

.confirmation-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
}

.attendance-options {
  display: flex;
  justify-content: center;
  padding: 1em;

}

.form-group {
  display: flex;
  align-items: center;
}

.form-group label {
  margin-right: 5px; /* Espacio entre el texto y el radio button */
}

.form-group input[type="radio"] {
  margin-right: 5px; /* Espacio entre los radio buttons */
}

/* Estilo para el modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegura que esté por encima de otros elementos */
}

/* Contenido del modal */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 80%; /* Ancho del contenido */
  max-width: 600px; /* Ancho máximo del contenido */
}

/* Botón para cerrar el modal */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #680517;
}

/* Contenido adicional del modal */
.modal-content p {
  margin-bottom: 20px;
}
