.gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 6 columnas en desktop */
    gap: 10px; /* Espacio entre elementos */
    padding: 2em;
  }
  
  .gallery-item {
    /* Estilos para los elementos de la galería */
  }
  
  .gallery-item img {
    border-radius: 10px;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Media query para tablet */
  @media (max-width: 768px) {
    .gallery {
      grid-template-columns: repeat(3, 1fr); /* 3 columnas en tablet */
    }
  }
  
  /* Media query para móvil */
  @media (max-width: 480px) {
    .gallery {
      grid-template-columns: repeat(2, 1fr); /* 2 columnas en móvil */
    }
    .gallery-item img {
      border-radius: 10px;
      max-width: 100%;
      height: 250px;
      object-fit: cover;
    }
  }
  