.event-info {
    text-align: center;
  }
  
  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .button {
    margin: 0 10px;
  }
  
  .copied-msg {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    animation: moveUp 2s ease-out;
  }
  
  @keyframes moveUp {
    0% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    50% {
      opacity: 1;
      transform: translate(-50%, -20px);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -40px);
    }
  }
  