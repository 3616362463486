@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quattrocento+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellefair&display=swap');

.title-cursive{
    font-family: 'Great Vibes', cursive;
    font-size: 3rem;
    font-weight: 'bold';
    margin-bottom: 1rem;
    margin-top: 2rem
}
.title{
    color: white;
}
.main-text{
    margin: 1em;
}
h1{

    font-family: 'Bellefair';
    font-size: 5rem;
    font-weight: 'regular';
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: white;
    
}
h2{
    font-family: 'EB Garamond';
    font-size: 3rem;
    font-weight: 'regular';
    margin-bottom: 1rem;
    margin-top: 1rem
}

p{
    font-family: 'EB Garamond';
    font-size: 1.5rem;
    font-weight: 'regular';
    margin-bottom: 1rem;
    margin-top: 1rem
}

.address{
    font-family: 'EB Garamond';
    font-weight: 'regular';
    margin-top: 1rem;
    font-size: 1.2rem;

}
.contact-text{
    font-family: 'EB Garamond';
    font-weight: 'regular';
    margin-top: 1rem;
    font-size: 1.2rem;
}
.timer{
    font-family: 'EB Garamond';
    font-weight: 'regular';
    margin-top: 1rem;
    font-size: 2.5rem;
}
label{
    font-family: 'EB Garamond';
    font-weight: 'regular';
    margin-top: 0.5rem;
    font-size: 1.1rem;

}

button{
    font-family: 'EB Garamond';
    font-weight: 'regular';
}
.confirmation-message{
    font-family: 'EB Garamond';
    font-weight: 'regular';
    font-size: 1.2rem;

}


.scroll-effect {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    margin-top: 50px; /* Espacio adicional para evitar superposiciones con el encabezado */
    scroll-margin-top: 50px; /* Ajuste del margen de desplazamiento */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px); /* Desplazamiento hacia arriba al aparecer */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Desplazamiento suave */
  html {
    scroll-behavior: smooth;
  }
  